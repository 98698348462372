import { v4 as uuid } from 'uuid';
import {
  ReviewAction,
  ReviewType
} from '../lib/dataUtils';

export const getApplicationEnvironment = window.environment_get;
export const getCsrf = () => sessionStorage.getItem('csrf');

const MessageNames = Object.freeze({
  ProcedureLoad: 'checklist.procedure.load',
  FieldLoad: 'checklist.field.load',
  FeedInvalidate: 'feed.invalidate',
  ProcedureReview: 'checklist.procedure.review',
});

const ANSWERED_QUERY_COMMENT = "query changed to answered ";

export const requestProcedureLoad = context => {

  window.ws_send({
    id: uuid(),
    name: MessageNames.ProcedureLoad,
    protocol_id: context.protocolId,
    site_id: context.siteId,
    subject_id: context.subjectId,
    procedure_id: context.procedureId,
    parent_row: 0, // subject logs only (?)
    level: 0, // subject logs only
  });

};

export const addNewLog = args => {

  window.log_add_send(
    args.subjectId,
    args.procedureId,
    args.type,
    null,
    args.level,
    args.parentRow
  );
};

export const editLog = args => {

  window.log_edit_show(
    args.index,
    args.level,
    args.crossout,
    args.lock,
  );
};

export const buildReviewStatusDialog = data => {

  const {
    procedureId,
    rowIndex,
  } = data;

  return window.review_button_click.call({
    nav: {
      ...window.nav,
      procedure_id: procedureId,
      current_log_level: 0, // subject log level always 0
    },
    attributes: {
      'data-row' : { value: rowIndex },
      // review_button_click expects 'value' attribute,
      // but the value is ignored by the checklist.procedure.review endpoint
    },
  });
};

export const submitProcedureReviewDialogForm = async (reviewType, procedureContext, fieldValidations) => {

  const MAX_API_RESPONSE_DELAY = 5000; // ms
  const subscriptionChannel = 'procedureReviewData';

  return new Promise(( resolve, reject ) => {

    const distinctiveId = uuid();
    const subscriptionName = `message-${distinctiveId}`;

    const timeoutId = setTimeout(() => {

      window.PubSubManager.unsubscribe(subscriptionName,
                                       subscriptionChannel);
      reject();

    }, MAX_API_RESPONSE_DELAY);

    const handleResponse = message => {

      if(
        message.procedure_id !== procedureContext.procedureId
        || message.row !== procedureContext.procedureRowNumber
        ) {
        // freak accident: we caught a message intended for another subscriber!
        return;
      }

      clearTimeout( timeoutId );

      window.PubSubManager.unsubscribe(subscriptionName,
                                       subscriptionChannel);
      resolve( message );

    };

    window.PubSubManager.subscribe(subscriptionName,
               subscriptionChannel, handleResponse);

    window.review_action_do(reviewType, procedureContext.procedureId, procedureContext.procedureRowNumber, fieldValidations);
  });
};

export const buildReviewStatusHistory = data => {

  const {
    procedureId,
    rowIndex,
  } = data;

  return window.review_history_button_click.call({
    nav: {
      ...window.nav,
      procedure_id: procedureId,
    },
    attributes: {
      'data-row' : { value: rowIndex },
    },
  });
};

export const getFieldHistory = async context => {

  const MAX_API_RESPONSE_DELAY = 5000; // ms
  const subscriptionChannel = 'fieldHistory';

  return new Promise(( resolve, reject ) => {

    const messageId = uuid();
    const subscriptionName = `message-${messageId}`;

    const timeoutId = setTimeout(() => {

      window.PubSubManager.unsubscribe(subscriptionName,
                                       subscriptionChannel);
      reject();

    }, MAX_API_RESPONSE_DELAY);

    const handleResponse = message => {

      if( message?.aid !== messageId ) {
        return;
      }

      clearTimeout( timeoutId );

      window.PubSubManager.unsubscribe(subscriptionName,
                                       subscriptionChannel);
      resolve( message );

    };

    window.PubSubManager.subscribe(subscriptionName,
               subscriptionChannel, handleResponse);

    window.ws_send({
      id: messageId,
      name: MessageNames.FieldLoad,
      protocol_id: context.protocolId,
      site_id: context.siteId,
      protocol_instance: context.protocolVersionId,
      protocol_branch: context.protocolVersionName,
      field_instance_id: context.fieldId,
    });
  });

};

/**
runs field_save(), defined in ../classic/js/src/visit.page.js relative to
webapp/
*/

export const saveField = async data => {

  const MAX_API_RESPONSE_DELAY = 5000; // ms
  const subscriptionChannel = 'fieldSaveResponse';

  const { fieldDefinitionId, reasonForChange, supplementaryData } = data;

  return new Promise(( resolve, reject ) => {

    const messageId = uuid();
    const subscriptionName = `message-${messageId}`;

    const timeoutId = setTimeout(() => {

      window.PubSubManager.unsubscribe(subscriptionName,
                                       subscriptionChannel);
      reject();

    }, MAX_API_RESPONSE_DELAY);

    const handleResponse = message => {

      if( message?.aid !== messageId ) {
        return;
      }

      clearTimeout( timeoutId );

      window.PubSubManager.unsubscribe(subscriptionName,
                                       subscriptionChannel);
      resolve();

    };

    window.PubSubManager.subscribe(subscriptionName,
               subscriptionChannel, handleResponse);

    // originally guidance: false
    // originally: procedure_state is filled (state, state_text)

    window.field_save( fieldDefinitionId, reasonForChange,
                       {
                         messageId,
                         ...supplementaryData,
                       });
  }); // Promise

};

const invalidateSubjectLogEvidencePage = async message => {

  const MAX_API_RESPONSE_DELAY = 5000; // ms
  const subscriptionChannel = 'subjectLogProcedureEvidenceInvalidation';

  return new Promise(( resolve, reject ) => {

    const messageId = message.id;
    const subscriptionName = `message-${messageId}`;

    const timeoutId = setTimeout(() => {

      window.PubSubManager.unsubscribe(subscriptionName,
                                       subscriptionChannel);
      reject();

    }, MAX_API_RESPONSE_DELAY);


    const handleResponse = responseMessage => {

      if( responseMessage?.aid !== messageId ) {
        return;
      }

      clearTimeout( timeoutId );

      window.PubSubManager.unsubscribe(subscriptionName,
                                       subscriptionChannel);
      resolve(responseMessage);

    };

    window.PubSubManager.subscribe(subscriptionName,
               subscriptionChannel, handleResponse);

    window.ws_send( message );

  }); // Promise

};

export const invalidateSubjectLogEvidencePages = async data => {

  // follows evidence_invalidate_continue()
  //
  // evidence_invalidate_continue couples message construction and DOM value
  // storage too intimately, so here we'll pursue message construction only.
  // Further, we'll restrict message construction to the subject log case.
  // Consequently, we don't have to consider alternative message structures
  // for visit procedures and site logs here.
  //
  // To simplify the process further, we'll drop support for 'document'
  // invalidation, restricting our support to only 'page' invalidation. This
  // is able to accomplish 'document' invalidation simply by invalidating
  // all of a document's pages.


  const {
    context,
    evidenceInvalidationReason,
    pageIds,
  } = data;

  const now = Date.now();

  const commonMessage = {
    //id: uuid(), // use new UUID for each message
    name: MessageNames.FeedInvalidate,
    protocol_id: context.protocolId,
    site_id: context.siteId,
    subject_id: context.subjectId,
    //feed_id: handle in loop
    rfc: evidenceInvalidationReason,
    event_time: now,
    invalid_type: 'page', // we'll just invalidate individual pages: simpler
    // don't support visit procedures here: visit_instance_id, proc_id
    // protocol_instance: context.protocolVersionId, // unused at service
    protocol_branch: context.protocolVersionName, // also unused at service
    type: 'subject', // entity type: 'subject' log or 'site' log
    entity_id: context.subjectId, // redundant!
    procedure_id: context.procedureId,
    level: 0, // level 0, for subject logs only
    row: context.procedureRowNumber,
    parent_row: 0, // subject logs only (?)
    //field_instance_id: context.fieldId,
  };

  const messages = pageIds.map(pageId => ({
      ...commonMessage,
      id: uuid(),
      feed_id: pageId,
  }));

  for ( const message of messages) {
    // call these serially to avoid concurrency issues
    await invalidateSubjectLogEvidencePage( message );
  }

};

export const showEvidenceAddDialog = data => {

  const {
    procedureId,
    procedureRowNumber,
  } = data;

  window.evidence_show_modal.call({
      nav: {
        evidence_signature: [],
      },
    },
    // onclick="evidence_show_modal('{{repeat_idx}}:{{procedure_id}}', {{row}}, {{level}});
   `:${procedureId}`,
   procedureRowNumber,
   0, // level 0, for subject logs only

   );
};

export const showSignatureView = data => {

  const {
    feedId,
    date,
    signerFirstName,
    signerLastName,
    signerAffiliation,
    isDownloadable,

  } = data;

  const applicationEnvironment = getApplicationEnvironment();

  window.evidence_signature_view(
    `${applicationEnvironment.rest}/feed/get/${feedId}/signature`,
    date,
    `${signerFirstName} ${signerLastName}, ${signerAffiliation}`,
    feedId,
    null, // procedureName, absent for log rows
    isDownloadable
   );
};

export const selectSite = args => {

  const {

    protocolId,
    protocolVersionName,
    siteId,
    siteDisplayName, // `${siteNumber} - ${siteNumber} - ${piName}`

  } = args;

  window.site_page_load(
    protocolId,
    protocolVersionName,
    siteId,
    siteDisplayName
  );

};

export const selectSubject = args => {

  const {

    protocolId,
    protocolVersionName,
    siteId,
    siteDisplayName, // `${siteNumber} - ${siteNumber} = ${piName}`

    subjectName,
    subjectId,

  } = args;

  selectSite({

    protocolId,
    protocolVersionName,
    siteId,
    siteDisplayName,

  });

  window.subject_page_load(
    subjectName,
    subjectId,
    false,
  );

};

export const indicateSelectedFieldForQueryDetail = (
                                         fieldDefinitionId, isSelected) => {
  // https://stackoverflow.com/questions/195951
  //        /how-can-i-change-an-elements-class-with-javascript
  if(isSelected){
    document.getElementById(`item-${fieldDefinitionId}`)
               ?.classList?.add('selected-field-for-query-detail');
    return;
  }

  document.getElementById(`item-${fieldDefinitionId}`)
    ?.classList?.remove('selected-field-for-query-detail');
};

const setElementVisibilityById = (elementId, isVisible) => {
  if(isVisible){
    document.getElementById(elementId)?.classList?.remove('hidden');
    return;
  }

  document.getElementById(elementId)?.classList?.add('hidden');
};

export const setTabContentVisibility = isVisible => {
  setElementVisibilityById('tab-content', isVisible);
};

export const setDashboardContentVisibility = isVisible => {
  setElementVisibilityById('dashboard-content', isVisible);
};

export const requestSiteList = args => {

  const {

    protocolId,
    protocolNumber,

  } = args;

  window.site_list_get(
    protocolId,
    protocolNumber,
    0
  );

};

export const requestExportList = args => {

  const {

    protocolId,
    protocolNumber,

  } = args;

  window.export_list_get(
    protocolId,
    protocolNumber
  );

};

export const requestArtifactList = args => {

  const {

    protocolId,

  } = args;

  window.artifact_listing_initial( protocolId );

};

export const requestReportList = args => {

  const {

    protocolId,

  } = args;

  window.report_listing_initial( protocolId );

};

export const saveGuidance = async data => {

  const MAX_API_RESPONSE_DELAY = 5000; // ms
  const subscriptionChannel = 'fieldSaveResponse';

  const { fieldDefinitionId, supplementaryData } = data;

  return new Promise(( resolve, reject ) => {

    const messageId = uuid();
    const subscriptionName = `message-${messageId}`;

    const timeoutId = setTimeout(() => {

      window.PubSubManager.unsubscribe(subscriptionName,
                                       subscriptionChannel);
      reject();

    }, MAX_API_RESPONSE_DELAY);

    const handleResponse = message => {

      if( message?.aid !== messageId ) {
        return;
      }

      clearTimeout( timeoutId );

      window.PubSubManager.unsubscribe(subscriptionName,
                                       subscriptionChannel);
      resolve();

    };

    window.PubSubManager.subscribe(subscriptionName,
               subscriptionChannel, handleResponse);

    window.field_save( fieldDefinitionId, null, {
      messageId,
      ...supplementaryData,
    } );
  }); // Promise

};

export const trackEvidencePage = ( pageId, date ) => {
  window.send_feed_view_save( pageId, date );
};

export const refreshProcedure = (context) => {
  const requestBody = {
    action: ReviewAction.AnswerQuery,
    comment: ANSWERED_QUERY_COMMENT + uuid(),
    id: uuid(),
    name: MessageNames.ProcedureReview,
    review_type: ReviewType.Visit,
    procedure_id: context.procedureId,
    repeat_idx: context.procedureRepeatIndex,
    protocol_branch: context.protocolBranch,
    protocol_id: context.protocolId,
    protocol_instance: context.protocolInstanceId,
    site_id: context.siteId,
    subject_id: context.subjectId,
    visit_id: context.visitIdChar,
    visit_instance_id: context.visitId,
  }
  window.ws_send( requestBody );
}

export const resetSessionTimeout = () => {
  window.session_timeout_reset();
};

export const requestSubjectHistory = ({
  protocolId,
  siteId,
  subjectId,
}) => {
  const message = {
    id: uuid(),
    protocol_id: protocolId,
    site_id: siteId,
    subject_id: subjectId,
    name: "subject.get",
    display: "history"
  };
  window.ws_send(message);
};

export const getCountryCode = (siteId) => {
  return window.search(window.sites, 'site_id', siteId)?.[0]?.country?.code;
};

export const getTimezone = ({
  userRole,
  countryCode,
  inputOffset,
  value,
}) => {
  return window.get_visit_timezone(
    userRole,
    countryCode,
    {
      actual_visit_date: value,
      actual_visit_date_offset: inputOffset,
    },
  );
};
