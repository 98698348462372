import {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';

import {
  Spin,
} from 'antd';

import deepEqual from 'deep-equal';

import {
  protocolDefinitionFromData,
  procedureDefinitionFromProtocolDefinition,
  dataEntryFromProcedureDefinition,
} from '../lib/protocolDefinitionUtils';

import {
  reviewStatusFromSubjectLogProcedureData,
} from '../lib/dataUtils';

import {
  isSubjectLogRowLocked as checkIsSubjectLogRowLocked,
} from '../lib/lockUtils';

import {
  isProcedureHasState,
  getProcedureState,
} from '../lib/procedureFormUtils';

import { useDataRequest } from '../hook/useDataRequest';

import {
  setTabContentVisibility,
} from '../legacy/LegacyFacade';

import {
  findQueryRole,
  protocolUserRoleFromUserData,
} from '../lib/roleUtils';

import { Guidance } from './procedure/Guidance';
import { Notice } from './procedure/Notice';
import { DataEntryView } from './procedure/DataEntryView';
import {
  ProcedureEvidence,
  isProcedureCertifyCopyEnabled,
} from './procedure/ProcedureEvidence';
import {
  SubjectLogProcedureViewHeader,
  SimplifiedSubjectLogProcedureViewHeader,
} from './procedure/SubjectLogProcedureViewHeader';
import { useGetFieldReviews } from '../hook/http-restapi/useGetFieldReviews';

import './SubjectLogProcedureItemView.scss';

const ProtocolVersionLabel = props => {
  const { protocolVersionName } = props;

  if( !protocolVersionName ) {
    return null;
  }
  return (
    <div className='ProtocolVersionLabel'>
      Protocol Version: { protocolVersionName }
    </div>
  );
};

// several helper functions:

const getFieldsData = (procedureDefinition,
  subjectLogProcedureData, subjectLogProcedureSelection) => {
  const dataEntry = dataEntryFromProcedureDefinition(procedureDefinition);

  const newFieldsData = [];
  for (const type in dataEntry) {
    const entryTypeFieldData = dataEntry[type].reduce(
      (accumulator, dataEntryFieldItem) => {
        const fieldItem = subjectLogProcedureData.fields.find(
          f => dataEntryFieldItem.id === f.field_id
            && f.procedure_row === subjectLogProcedureSelection.index);
        if (fieldItem) {
          return [...accumulator, fieldItem];
        }
        return accumulator;
      }, []);
    newFieldsData.push(...entryTypeFieldData);
  };

  return newFieldsData;

};

const getClassicProcedureState = (procedureDefinition, fieldsData) => {

  if(!isProcedureHasState(procedureDefinition, 0)) {
    return null;
  }

  const state = getProcedureState( procedureDefinition, fieldsData );

  if(state?.isDefaultProcedureState) {
    return {
      state: 'fail',
      state_text: 'Data Incomplete',
    };
  }

  if(!state?.final) {
    return {
      state: 'in-between',
      state_text: state.state,
    };
  }

  return {
    state: 'final',
    state_text: state.state,
  };
};

export const SimplifiedSubjectLogProcedureItemView = props => {
  const {
    userData,

    protocolData,
    siteInfo,
    subjectData,
    subjectLogProcedureData,
    subjectLogProcedureSelection,

    refreshSubjectLogProcedureData,

  } = props;

  const [ currentProcedureData, setCurrentProcedureData ] = useState( null );
  const [ currentProcedureSelection,
       setCurrentProcedureSelection ] = useState( null );
  const [ protocolVersionId, setProtocolVersionId ] = useState(null);
  const [ protocolDefinition, setProtocolDefinition ] = useState(null);
  const [ procedureDefinition, setProcedureDefinition ] = useState(null);

  const [ fieldsData, setFieldsData ] = useState(null);
  const [ isDataSaveInProgress, setIsDataSaveInProgress ] = useState(false);

  if(!subjectLogProcedureData?.fields?.[0]) {
    // we need at least one field to look up the procedure name
    throw new Error('Unexpected data error in subjectLogProcedureData');
  }

  useEffect(() => {

    setCurrentProcedureData( subjectLogProcedureData );
    setIsDataSaveInProgress( () => false );

  }, [ subjectLogProcedureData ]);

  useEffect(() => {

    setCurrentProcedureSelection( subjectLogProcedureSelection );

  }, [ subjectLogProcedureSelection ]);

  useEffect(() => {

    if(!protocolData?.protocol_list) {
      return;
    }

    if(!currentProcedureData || !currentProcedureSelection) {
      return;
    }

    const protocolVersionName = currentProcedureData?.evidence?.find(i =>
      i.row === currentProcedureSelection?.index)?.protocol_branch;

    setProtocolVersionId( () => protocolData.protocol_list.find(
        v => v.protocol_branch === protocolVersionName
      ).protocol_instance
    );
  }, [
    protocolData?.protocol_list,
    currentProcedureData,
    currentProcedureSelection,
  ]);

  useEffect(() => {

    if(!protocolData
       || !siteInfo
       || !currentProcedureData
       || !currentProcedureSelection) {
      return;
    }

    // protocolVersionName is the current Procedure Data Protocol Branch. e.g, 1.0, 2.0
    const protocolVersionName = currentProcedureData.evidence
      .find(rowData => rowData.row === currentProcedureSelection.index)
      .protocol_branch;

    const logEntryData = currentProcedureData.evidence
    .find(rowData => rowData.row === currentProcedureSelection.index);


    const siteProtocolBranchObject = siteInfo.protocolBranches.find(branch => branch.branch === protocolVersionName);
    let protocolInstance = null;
    if (siteProtocolBranchObject?.protocol_instance && siteProtocolBranchObject?.date_updated > logEntryData.date_created) {
      protocolInstance = siteProtocolBranchObject.protocol_instance;
    }

    const protocolDefinition = protocolDefinitionFromData(
      protocolData,
      siteInfo.protocol_id,
      protocolVersionName,
      protocolInstance,
    );

    const newProcedureDefinition = procedureDefinitionFromProtocolDefinition(
      protocolDefinition, currentProcedureData.procedure_id
    );

    setProtocolDefinition( () => protocolDefinition );
    setProcedureDefinition( () => newProcedureDefinition );

    const newFieldsData = getFieldsData(newProcedureDefinition,
      currentProcedureData, currentProcedureSelection);

    setFieldsData( oldFieldsData => {

      if(!oldFieldsData || oldFieldsData.length !== newFieldsData.length) {
        return newFieldsData;
      }

      if(deepEqual(oldFieldsData, newFieldsData)) {
        return oldFieldsData;
      }

      // replace container so status is re-rendered
      // but keep original items where value is unchanged
      const result = [];

      for (const newFieldData of newFieldsData) {

        const oldFieldData = oldFieldsData.find(
          d => d.field_instance_id === newFieldData.field_instance_id);

        if(!oldFieldData) {
          return newFieldsData;
        }

        if(deepEqual( oldFieldData, newFieldData )) {
          result.push(oldFieldData);
        } else {
          result.push(newFieldData);
        }
      }
      return result;

    });

  }, [
     protocolData,
     siteInfo,
     currentProcedureData,
     currentProcedureSelection,
  ]);

  const calculateNewProcedureState = useCallback(( fieldId, newValue ) => {
    const newFieldsData = fieldsData.map(f => {
      if(f.field_id !== fieldId) {
        return f;
      }
      return {
        ...f,
        data: [
          {
            ...(f.data?.[0] ?? []),
            value: newValue,
          }
        ],
      };
    });

    const newProcedureState = getClassicProcedureState( procedureDefinition,
                                                   newFieldsData);
    return newProcedureState;

  }, [ fieldsData, procedureDefinition ]);

  useEffect(() => {

    setTabContentVisibility( !Number.isFinite(
      currentProcedureSelection?.index ));

    return () => setTabContentVisibility( true );

  }, [ currentProcedureSelection?.index ]);

  const context = useMemo(() => ({
    protocolId: siteInfo?.protocol_id,
    protocolVersionName: currentProcedureData?.evidence?.find(i =>
      i.row === currentProcedureSelection?.index)?.protocol_branch,
    siteId: siteInfo?.siteId,
    subjectId: currentProcedureData?.subject_id,
    procedureId: currentProcedureData?.procedure_id,
    procedureRowNumber: currentProcedureSelection?.index,
    isSiteLocked: siteInfo?.isLocked,
  }),
  [
     currentProcedureData?.evidence,
     currentProcedureData?.procedure_id,
     currentProcedureData?.subject_id,
     currentProcedureSelection?.index,
     siteInfo?.protocol_id,
     siteInfo?.siteId,
     siteInfo?.isLocked,
   ]);

  const userRole = protocolUserRoleFromUserData(
    userData, context.protocolId);
  const principalQueryRole = findQueryRole( userRole );

  const queryListRequest = useDataRequest({
    endpoint: 'gui/queryList',
    context,
    config: { enabled: !!context?.procedureId },
    paging: {
      exclusiveStartKey: null,
      maxResults: null,
    },
    sort: {
      field: principalQueryRole === 'initiator'
        ? 'dm-status'
        : 'site-status',
      isAscending: true,
    },
    filter: null,
  });

  const getFieldReviewsRequest = useGetFieldReviews({
    protocolId: context.protocolId,
    siteId: context.siteId,
    subjectId: context.subjectId,
  });

  if(subjectLogProcedureData.procedure_id
     !== currentProcedureData?.procedure_id) {

    return 'Loading...';
  }

  if(
    !currentProcedureSelection
    || subjectLogProcedureSelection.index
         !== currentProcedureSelection?.index) {

    return 'Loading...';
  }

  if(!procedureDefinition) {
    return 'Loading...';
  }

  if(!subjectData) {
    return 'Loading...';
  }

  if(!Boolean(queryListRequest?.data)) {
    return 'Loading...';
  }

  // back stop
  if(currentProcedureSelection.crossout) {
    return null;
  }

  const isSubjectLogRowLocked = checkIsSubjectLogRowLocked(
    subjectData, subjectLogProcedureData, context );

  const {
    guidance,
    notice,
  } = procedureDefinition.collection[0];

  const branchesData = userData?.protocols.find(
    protocol => protocol.protocol_id === siteInfo.protocol_id).branches;

  const branchData = branchesData?.find(
          b => b?.instance?.production === protocolVersionId);

   const isEvidenceEnabled = isProcedureCertifyCopyEnabled(
     protocolDefinition.study, procedureDefinition
   );

  const reviewStatus = reviewStatusFromSubjectLogProcedureData(
    currentProcedureData?.review, currentProcedureSelection.index
  );

  return (
    <div className='SimplifiedSubjectLogProcedureItemView' >
  
    <Spin spinning={isDataSaveInProgress}>
      <div className='SubjectLogProcedureItemEditForm'
           id='SubjectLogProcedureItemEditForm'
           >
        <div className='procedure'>
  
          <SimplifiedSubjectLogProcedureViewHeader {...{
  
            userData,
  
            procedureDefinition,
  
            context,
            protocolData,
            currentProcedureData,
            currentProcedureSelection,
  
            fieldsData,
            isSubjectLogRowLocked,
  
            queryListRequest,
  
          }}/>
  
          <Notice notice={notice}/>
  
          <Guidance {...{
            userRole,
  
            protocolVersionId,
            siteInfo,
            procedureData: currentProcedureData,
            procedureSelection: currentProcedureSelection,
  
            guidance,
            branchData,
  
            isSubjectLogRowLocked,
            reviewStatus,
            procedureQueryItems: queryListRequest?.data?.items,
  
            calculateNewProcedureState,
            refreshSubjectLogProcedureData,
          }}/>
  
          <DataEntryView {...{
            userData,
  
            context,
            protocolData,
            protocolVersionId,
            siteInfo,
            procedureData: currentProcedureData,
            subjectLogProcedureSelection,
  
            procedureDefinition,
  
            fieldsData,
  
            isDataSaveInProgress,
            isSubjectLogRowLocked,
            reviewStatus,
  
            procedureQueryItems: queryListRequest?.data?.items,
            fieldReviewItems: getFieldReviewsRequest?.data,
  
            setIsDataSaveInProgress,
            refreshSubjectLogProcedureData,
            calculateNewProcedureState,
          }}/>
  
          { isEvidenceEnabled && (
  
            <ProcedureEvidence {...{
              userData,
              context,
              evidence: currentProcedureData?.evidence?.find(i =>
                           i.row === currentProcedureSelection?.index),
              refreshSubjectLogProcedureData,
              isSubjectLogRowLocked,
              reviewStatus,
              procedureQueryItems: queryListRequest?.data?.items,
              isEvidenceDownloadAllowed:
                procedureDefinition?.downloadSource ?? false,
            }}/>
  
          )}
  
          <ProtocolVersionLabel
            protocolVersionName={ context.protocolVersionName }
          />
        </div>
  
      </div>
    </Spin>
    </div>
  );

};

export const SubjectLogProcedureItemView = props => {
  console.log('SubjectLogProcedureItemView Finnaly');
  const {
    userData,

    protocolData,
    siteInfo,
    subjectData,
    subjectLogProcedureData,
    subjectLogProcedureSelection,

    refreshSubjectLogProcedureData,
    resetSubjectLogProcedureSelection,

  } = props;

  const [ currentProcedureData, setCurrentProcedureData ] = useState( null );
  const [ currentProcedureSelection,
       setCurrentProcedureSelection ] = useState( null );
  const [ protocolVersionId, setProtocolVersionId ] = useState(null);
  const [ protocolDefinition, setProtocolDefinition ] = useState(null);
  const [ procedureDefinition, setProcedureDefinition ] = useState(null);

  const [ fieldsData, setFieldsData ] = useState(null);
  const [ isDataSaveInProgress, setIsDataSaveInProgress ] = useState(false);

  if(!subjectLogProcedureData?.fields?.[0]) {
    // we need at least one field to look up the procedure name
    throw new Error('Unexpected data error in subjectLogProcedureData');
  }

  useEffect(() => {

    setCurrentProcedureData( subjectLogProcedureData );
    setIsDataSaveInProgress( () => false );

  }, [ subjectLogProcedureData ]);

  useEffect(() => {

    setCurrentProcedureSelection( subjectLogProcedureSelection );

  }, [ subjectLogProcedureSelection ]);

  useEffect(() => {

    if(!protocolData?.protocol_list) {
      return;
    }

    if(!currentProcedureData || !currentProcedureSelection) {
      return;
    }

    const protocolVersionName = currentProcedureData?.evidence?.find(i =>
      i.row === currentProcedureSelection?.index)?.protocol_branch;

    // TODO: Check where this uese efect is been used and if we should add the protocolInstance
    /*const protocolInstance = currentProcedureData?.evidence?.find(i =>
      i.row === currentProcedureSelection?.index)?.protocol_instance;

    console.log('protocolInstance', protocolInstance);*/

    setProtocolVersionId( () => protocolData.protocol_list.find(
        v => v.protocol_branch === protocolVersionName
      ).protocol_instance
    );
    console.log('protocolVersionId from setProtocolVersionId', protocolVersionId);
  }, [
    protocolData?.protocol_list,
    currentProcedureData,
    currentProcedureSelection,
  ]);

  useEffect(() => {

    if(!protocolData
       || !siteInfo
       || !currentProcedureData
       || !currentProcedureSelection) {
      return;
    }

    // protocolVersionName is the current Procedure Data Protocol Branch. e.g, 1.0, 2.0
    const protocolVersionName = currentProcedureData.evidence
      .find(rowData => rowData.row === currentProcedureSelection.index)
      .protocol_branch;

    const logEntryData = currentProcedureData.evidence
    .find(rowData => rowData.row === currentProcedureSelection.index);

    console.log('siteInfo', siteInfo, 'protocolData', protocolData, 'protocolVersionName', protocolVersionName, 'subjectLogProcedureData', currentProcedureData, 'currentProcedureSelection', currentProcedureSelection);

    const siteProtocolBranchObject = siteInfo.protocolBranches.find(branch => branch.branch === protocolVersionName);
    let protocolInstance = null;
    if (siteProtocolBranchObject?.protocol_instance && siteProtocolBranchObject?.date_updated > logEntryData.date_created) {
      protocolInstance = siteProtocolBranchObject.protocol_instance;
    }

    console.log('siteProtocolBranchObject', siteProtocolBranchObject, 'protocolInstance', protocolInstance);
    const protocolDefinition = protocolDefinitionFromData(
      protocolData,
      siteInfo.protocol_id,
      protocolVersionName,
      protocolInstance,
    );

    const newProcedureDefinition = procedureDefinitionFromProtocolDefinition(
      protocolDefinition, currentProcedureData.procedure_id
    );

    setProtocolDefinition( () => protocolDefinition );
    setProcedureDefinition( () => newProcedureDefinition );

    const newFieldsData = getFieldsData(newProcedureDefinition,
      currentProcedureData, currentProcedureSelection);

    setFieldsData( oldFieldsData => {

      if(!oldFieldsData || oldFieldsData.length !== newFieldsData.length) {
        return newFieldsData;
      }

      if(deepEqual(oldFieldsData, newFieldsData)) {
        return oldFieldsData;
      }

      // replace container so status is re-rendered
      // but keep original items where value is unchanged
      const result = [];

      for (const newFieldData of newFieldsData) {

        const oldFieldData = oldFieldsData.find(
          d => d.field_instance_id === newFieldData.field_instance_id);

        if(!oldFieldData) {
          return newFieldsData;
        }

        if(deepEqual( oldFieldData, newFieldData )) {
          result.push(oldFieldData);
        } else {
          result.push(newFieldData);
        }
      }
      return result;

    });

  }, [
     protocolData,
     siteInfo,
     currentProcedureData,
     currentProcedureSelection,
  ]);

  const calculateNewProcedureState = useCallback(( fieldId, newValue ) => {
    const newFieldsData = fieldsData.map(f => {
      if(f.field_id !== fieldId) {
        return f;
      }
      return {
        ...f,
        data: [
          {
            ...(f.data?.[0] ?? []),
            value: newValue,
          }
        ],
      };
    });

    const newProcedureState = getClassicProcedureState( procedureDefinition,
                                                   newFieldsData);
    return newProcedureState;

  }, [ fieldsData, procedureDefinition ]);

  useEffect(() => {

    setTabContentVisibility( !Number.isFinite(
      currentProcedureSelection?.index ));

    return () => setTabContentVisibility( true );

  }, [ currentProcedureSelection?.index ]);

  const context = useMemo(() => ({
    protocolId: siteInfo?.protocol_id,
    protocolVersionName: currentProcedureData?.evidence?.find(i =>
      i.row === currentProcedureSelection?.index)?.protocol_branch,
    siteId: siteInfo?.siteId,
    subjectId: currentProcedureData?.subject_id,
    procedureId: currentProcedureData?.procedure_id,
    procedureRowNumber: currentProcedureSelection?.index,
    isSiteLocked: siteInfo?.isLocked,
  }),
  [
     currentProcedureData?.evidence,
     currentProcedureData?.procedure_id,
     currentProcedureData?.subject_id,
     currentProcedureSelection?.index,
     siteInfo?.protocol_id,
     siteInfo?.siteId,
     siteInfo?.isLocked,
   ]);

  const userRole = protocolUserRoleFromUserData(
    userData, context.protocolId);
  const principalQueryRole = findQueryRole( userRole );

  const queryListRequest = useDataRequest({
    endpoint: 'gui/queryList',
    context,
    config: { enabled: !!context?.procedureId },
    paging: {
      exclusiveStartKey: null,
      maxResults: null,
    },
    sort: {
      field: principalQueryRole === 'initiator'
        ? 'dm-status'
        : 'site-status',
      isAscending: true,
    },
    filter: null,
  });

  const getFieldReviewsRequest = useGetFieldReviews({
    protocolId: context.protocolId,
    siteId: context.siteId,
    subjectId: context.subjectId,
  });

  if(subjectLogProcedureData.procedure_id
     !== currentProcedureData?.procedure_id) {

    return 'Loading...';
  }

  if(
    !currentProcedureSelection
    || subjectLogProcedureSelection.index
         !== currentProcedureSelection?.index) {

    return 'Loading...';
  }

  if(!procedureDefinition) {
    return 'Loading...';
  }

  if(!subjectData) {
    return 'Loading...';
  }

  if(!Boolean(queryListRequest?.data)) {
    return 'Loading...';
  }

  // back stop
  if(currentProcedureSelection.crossout) {
    return null;
  }

  const isSubjectLogRowLocked = checkIsSubjectLogRowLocked(
    subjectData, subjectLogProcedureData, context );

  const {
    guidance,
    notice,
  } = procedureDefinition.collection[0];

  const branchesData = userData?.protocols.find(
    protocol => protocol.protocol_id === siteInfo.protocol_id).branches;

  const branchData = branchesData?.find(
          b => b?.instance?.production === protocolVersionId);

   const isEvidenceEnabled = isProcedureCertifyCopyEnabled(
     protocolDefinition.study, procedureDefinition
   );

  const reviewStatus = reviewStatusFromSubjectLogProcedureData(
    currentProcedureData?.review, currentProcedureSelection.index
  );

  return (
    <div className='SubjectLogProcedureItemView' >
  
      <Spin spinning={isDataSaveInProgress}>
        <div className='SubjectLogProcedureItemEditForm'
             id='SubjectLogProcedureItemEditForm'
             >
          <div className='procedure'>
    
            <SubjectLogProcedureViewHeader {...{
    
              userData,
    
              procedureDefinition,
    
              context,
              protocolData,
              currentProcedureData,
              currentProcedureSelection,
    
              fieldsData,
              isSubjectLogRowLocked,
    
              queryListRequest,
              getFieldReviewsRequest,
              resetSubjectLogProcedureSelection,
    
              refreshSubjectLogProcedureData,
    
            }}/>
    
            <Notice notice={notice}/>
    
            <Guidance {...{
              userRole,
    
              protocolVersionId,
              siteInfo,
              procedureData: currentProcedureData,
              procedureSelection: currentProcedureSelection,
    
              guidance,
              branchData,
    
              isSubjectLogRowLocked,
              reviewStatus,
              procedureQueryItems: queryListRequest?.data?.items,
    
              calculateNewProcedureState,
              refreshSubjectLogProcedureData,
            }}/>
    
            <DataEntryView {...{
              userData,
    
              context,
              protocolData,
              protocolVersionId,
              siteInfo,
              procedureData: currentProcedureData,
              subjectLogProcedureSelection,
    
              procedureDefinition,
    
              fieldsData,
    
              isDataSaveInProgress,
              isSubjectLogRowLocked,
              reviewStatus,
    
              procedureQueryItems: queryListRequest?.data?.items,
              fieldReviewItems: getFieldReviewsRequest?.data,
    
              setIsDataSaveInProgress,
              refreshSubjectLogProcedureData,
              calculateNewProcedureState,
            }}/>
    
            { isEvidenceEnabled && (
    
              <ProcedureEvidence {...{
                userData,
                context,
                evidence: currentProcedureData?.evidence?.find(i =>
                             i.row === currentProcedureSelection?.index),
                refreshSubjectLogProcedureData,
                isSubjectLogRowLocked,
                reviewStatus,
                procedureQueryItems: queryListRequest?.data?.items,
                isEvidenceDownloadAllowed:
                  procedureDefinition?.downloadSource ?? false,
              }}/>
    
            )}
    
            <ProtocolVersionLabel
              protocolVersionName={ context.protocolVersionName }
            />
          </div>
    
        </div>
      </Spin>
    </div>
  );
};

