import {
  Button,
  Badge,
} from 'antd';

import {
  isEdcFromProtocolData,
  procedureDefinitionFromData,
  fieldDefinitionOrGridFromProcedureDefinition
} from '../../lib/protocolDefinitionUtils';

import {
  findQueryRole,
  protocolUserRoleFromUserData,
} from '../../lib/roleUtils';

import { SubjectLogProcedureFieldActionsDrawer }
                                    from './SubjectLogProcedureFieldActionsDrawer';
import FieldActionsButtons from '../FieldActionsButtons';

export const FieldActionsDisplayDrawerControl = props => {

  const {

    userData,

    context,
    protocolData,
    subjectLogProcedureData,

    procedureQueryItems,
    fieldReviewItems,
    isQueryDisplayVisible,
    isActive,
    isSubjectLogRowLocked,

    setIsQueryDisplayVisible,
    siteInfo,

  } = props;
  console.log('FieldActionsDisplayDrawerControl hello', siteInfo, protocolData, context, siteInfo, subjectLogProcedureData);
  const protocolUserRole = protocolUserRoleFromUserData( userData, context.protocolId );

  const principalQueryRole = findQueryRole(protocolUserRole);

  const field = subjectLogProcedureData?.fields?.filter( f =>
    f.field_instance_id === context.fieldId);

  const fieldQueries = procedureQueryItems.filter(item =>
    item?.context?.fieldId === context?.fieldId);

  const procedureDefinition = procedureDefinitionFromData(protocolData,
    context.protocolId, context.protocolVersionName, context.procedureId);

  const fieldDefinition = field?.[0]?.field_id 
    ? fieldDefinitionOrGridFromProcedureDefinition(procedureDefinition, field?.[0]?.field_id) 
    : null;
  
  const isFieldReviewEnabled = fieldDefinition?.reviewable;

  const fieldReview = fieldReviewItems?.find(
    item => item?.context?.fieldId === context?.fieldId
  );

  const openQueriesCount = fieldQueries.filter(
                             item => item.status === 'open'
                           ).length;
  const answeredQueriesCount = fieldQueries.filter(
                                 item => item.status === 'answered'
                               ).length;
  const closedQueriesCount = fieldQueries.filter(
                                item => item.status === 'closed'
                              ).length;

  if(openQueriesCount + answeredQueriesCount === 0
     && ( isSubjectLogRowLocked || principalQueryRole === null )) {
    return null;
  }

  const setSelectionState = isSelected => {
    setIsQueryDisplayVisible( () => isSelected );
  };

  const toggleDrawer = () => {
    setSelectionState(!isQueryDisplayVisible);
  };

  const isProcedureLevel = !Boolean(context?.fieldId);

  return (
    <>

      { isEdcFromProtocolData(protocolData, context.protocolVersionName)
        && isProcedureLevel
        && principalQueryRole === 'initiator'
        && !isSubjectLogRowLocked
        && (
        <Button
          disabled={isQueryDisplayVisible}
          id={'procedure-query-button-'+context.procedureId}
          className={openQueriesCount
                     ? 'ProcedureLevelQueryButton'
                     : 'ProcedureQueryDisplayControlButton'}
          hidden={isQueryDisplayVisible}
          onClick={(evt) => {
              toggleDrawer(evt);
            }
          }
        >
        {
          openQueriesCount || answeredQueriesCount
          ? 'Form-Level Queries  '
          : 'New Form-Level Query  '
        }
        { openQueriesCount > 0 && (
          <Badge
            className='EdcPlusProcedureLevelQueryBadge'
            count={openQueriesCount}
            showZero={true}
          />
        )}
        { answeredQueriesCount > 0  && (
          <Badge
            className='EdcPlusAnsweredProcedureLevelQueryBadge'
            count={answeredQueriesCount}
            showZero={true}
          />
        )}
        </Button>

      )}

      { isProcedureLevel
        && openQueriesCount + answeredQueriesCount > 0
        && ( principalQueryRole !== 'initiator'
             || (
                  principalQueryRole === 'initiator'
                  && isSubjectLogRowLocked
                )
           )
        && (
      <Button
        disabled={isQueryDisplayVisible}
        id={'procedure-query-button-'+context.procedureId}
        className={openQueriesCount
                   ? 'ProcedureLevelQueryButton'
                   : 'ProcedureQueryDisplayControlButton'}
        hidden={isQueryDisplayVisible}
        onClick={ () => toggleDrawer() }
      >
        Form-Level Queries
        { openQueriesCount > 0 && (
        <Badge
          className='EdcPlusProcedureLevelQueryBadge'
          count={openQueriesCount}
          showZero={true}
        />
        )}
        { answeredQueriesCount > 0 && (
        <Badge
          className='EdcPlusAnsweredProcedureLevelQueryBadge'
          count={answeredQueriesCount}
          showZero={true}
        />
        )}
      </Button>
      )}

      { isEdcFromProtocolData(protocolData, context.protocolVersionName)
        && !isProcedureLevel
        && (
        <FieldActionsButtons {...{
          isQueryDisplayVisible,
          userData,
          context,
          openQueriesCount,
          answeredQueriesCount,
          closedQueriesCount,
          fieldDefinitionId: fieldDefinition.id,
          toggleDrawer,
          principalQueryRole,
          protocolUserRole,
          isFieldReviewEnabled,
          fieldReview,
          isActive,
          isSplitView: false,
          queriesAreReadOnly: isSubjectLogRowLocked
                                || principalQueryRole === null,
        }} />
      )}

      <SubjectLogProcedureFieldActionsDrawer {...{
        userData,

        context,
        protocolData,
        field,
        siteInfo,
        subjectLogProcedureData,

        procedureQueryItems,
        isQueryDisplayVisible,
        isDisplayHasActiveQueries: (openQueriesCount > 0
                                 || answeredQueriesCount > 0),
        isQueriesAreReadOnly: isSubjectLogRowLocked
                              || principalQueryRole === null,

        setIsQueryDisplayVisible: setSelectionState,
      }}/>
    </>
  );
};

