import { useState } from 'react';
import {
  Modal,
  Button,
  Drawer,
  Tooltip,
} from 'antd';

import {
  fieldTextFromProtocolDefinitionFromSubjectLogProcedureData, // !!
} from '../../lib/protocolDefinitionUtils';

import { FieldActionsDisplay } from '../FieldActionsDisplay';


import {
  findQueryRole,
  protocolUserRoleFromUserData,
} from '../../lib/roleUtils';


export const SubjectLogProcedureFieldActionsDrawer = props => {

  const {
    userData,

    context,
    protocolData,
    field,
    siteInfo,
    subjectLogProcedureData,

    procedureQueryItems,
    isQueryDisplayVisible,
    isDisplayHasActiveQueries,
    isQueriesAreReadOnly,

    setIsQueryDisplayVisible,
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNewQueryFormHasValues, setIsNewQueryFormHasValues] = useState(false);

  const principalQueryRole = findQueryRole(
    protocolUserRoleFromUserData( userData, context.protocolId ));

  const [ isQueryListSelected, setIsQueryListSelected ]
            = useState( principalQueryRole !== 'initiator'
                        || isDisplayHasActiveQueries);

  if(!isQueryDisplayVisible) {
    return;
  }

  const checkIfFormHasValues = form => {
    const values = form?.getFieldsValue();
    if(values?.['message-text'] && values?.['message-text'].trim().length !== 0){
      return true;
    }
    return false;
  };

  const onClose = () => {
    if(isNewQueryFormHasValues){
      setIsModalVisible(true);
      return;
    }
    setIsQueryDisplayVisible( false );
  };

  const handleFormCancel = form => {

    if(checkIfFormHasValues(form)){
      setIsModalVisible(true);
      return;
    }
    setIsQueryDisplayVisible(false);
  };

  // dialog: abandon partially filled form?
  const handleOk = () => {
    setIsModalVisible(false);
    setIsQueryDisplayVisible(false);
  };

  // dialog: abandon partially filled form?
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  console.log('SubjectLogProcedureFieldActionsDrawer', context, protocolData, field, 'siteInfo', siteInfo);
  const siteProtocolBranchObject = siteInfo.protocolBranches.find(branch => branch.branch === context.protocolVersionName);
  console.log('siteProtocolBranchObject', siteProtocolBranchObject);
  
  const logEntryData = subjectLogProcedureData.evidence
    .find(rowData => rowData.row === context.procedureRowNumber);

  let protocolInstance = null;
  if (siteProtocolBranchObject?.protocol_instance && siteProtocolBranchObject?.date_updated > logEntryData?.date_created) {
    protocolInstance = siteProtocolBranchObject.protocol_instance;
  }

  console.log('siteProtocolBranchObject in the query drawer', siteProtocolBranchObject, 'protocolInstance', protocolInstance, 'logEntryData', logEntryData);
  const queryDisplayLabel =
    fieldTextFromProtocolDefinitionFromSubjectLogProcedureData(
      protocolData,
      context.protocolId,
      context.protocolVersionName,
      context.procedureId,
      field?.[0]?.field_id,
      protocolInstance
    );

  const toggleView = () => {
    setIsQueryListSelected( !isQueryListSelected )
  };

  const titleComponent = (<>
      <Tooltip className='EdcNewQueryFormTitle'
               placement='bottom'
               title={queryDisplayLabel}
               overlayClassName='EdcNewQueryFormTitleTooltip'
               >
        {queryDisplayLabel}
      </Tooltip>
      </>);

  const isFieldLevel = Boolean( context.fieldId );
  const scopedQueryItems = isFieldLevel
    ? procedureQueryItems?.filter(
        item => item?.context?.fieldId === context.fieldId )
    : procedureQueryItems?.filter(
        item => !Boolean( item?.context?.fieldId ));

  const extraComponent =
      principalQueryRole === 'initiator'
      && !isQueriesAreReadOnly
      ? (
        <Button
          className='EdcNewQueryViewQueriesButton'
          type='link'
          onClick={toggleView}
          disabled={ scopedQueryItems.length === 0 }
        >
          {isQueryListSelected ? 'New Query'
                               : isDisplayHasActiveQueries
                                 ? 'View Queries'
                                 : scopedQueryItems.length > 0
                                   ? 'View Closed Queries'
                                   : 'No Previous Queries'
          }
        </Button>
      )
      : null;

  return <>
      <Drawer
        className='SubjectLogProcedureFieldActionsDrawer'
        destroyOnClose={true}
        closable={true}
        visible={isQueryDisplayVisible}
        onClose={onClose}
        title={titleComponent}
        extra={extraComponent}

      >
        <FieldActionsDisplay
          {...{
            userData,

            context,

            procedureQueryItems,

            isQueryDisplayVisible,
            isQueryListSelected,
            isReadOnly: isQueriesAreReadOnly,

            handleFormCancel,
            setIsNewQueryFormHasValues,
            setIsQueryDisplayVisible,

          }}
        />
      </Drawer>
      {isModalVisible && <Modal  title='Leave Draft?'
              destroyOnClose={true}
              visible={true}
              onOk={handleOk}
              onCancel={handleCancel}
      >
        <p>Your draft will not be saved.</p>
      </Modal>}

  </>;
};

